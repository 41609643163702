.contacts {
  padding-bottom: 113px;
  padding-top: 86px;

  background-image: linear-gradient(
    to bottom,
    $primaryBgColor 10.5%,
    $secondaryBgColor 10.5%,
    $secondaryBgColor
  );
  @include media(768px) {
    padding-bottom: 158px;
    padding-top: 119px;

    background-image: linear-gradient(
      to bottom,
      $primaryBgColor 20.8%,
      $secondaryBgColor 20.8%,
      $secondaryBgColor
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  @include media(1280px) {
    padding-bottom: 165px;
    padding-top: 132px;

    background-image: url(..//..//src/images/contact.b-i.png),
      linear-gradient(to bottom, $primaryBgColor 23.4%, $secondaryBgColor 23.4%, $secondaryBgColor);
  }
}

.contacts__list {
  margin: 0 auto;
}

.contacts__list {
  margin-bottom: 54px;

  @include media(767px, max-width) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include media(768px) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-bottom: 81px;
  }
  @include media(1280px) {
    margin-left: -31px;
    margin-bottom: 86px;
  }
}

.contacts__item {
  width: 100%;
  min-height: 427px;
  padding: 43px 20px;
  margin: 0 auto;

  background-color: $lightTextColor;
  box-shadow: 0px 8px 30px rgba(212, 20, 67, 0.1);
  border-radius: 24px;

  @include media(768px) {
    flex-basis: calc(100% / 3 - 20px);
    margin-left: 20px;
    padding: 50px 32px 50px 31px;
  }
  @include media(1280px) {
    flex-basis: calc(100% / 3 - 31px);
    margin-left: 31px;
    padding: 51px 44px 62px 43px;
  }
}

.contacts__item:not(:last-child) {
  margin-bottom: 20px;

  @include media(768px) {
    margin-bottom: 0;
  }
}

.contacts__item-wrap {
  display: flex;
  align-items: center;
  height: 16px;
  width: 80px;
  margin-bottom: 25px;
  padding-left: 24px;
  padding-right: 24px;

  background-color: $coffeeBgColor;
  border-radius: 4px;

  @include media(768px) {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
    height: 28px;
    width: 73px;
  }
  @include media(1280px) {
    margin-bottom: 32px;
  }
}

.contacts__item-desc {
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  letter-spacing: 0.04em;
  color: $lightTextColor;

  @include media(768px) {
    font-size: 16px;
  }
}

.contacts__item-wrap.food {
  width: 130px;

  background-color: $milkshakeBgColor;

  @include media(768px) {
    width: 138px;
  }
}

.contacts__item-city {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.83;
  letter-spacing: 0.04em;
  color: $primaryTextColor;

  @include media(768px) {
    font-size: 14px;
  }
}

.contacts__item-text {
  margin-top: 10px;

  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: $secondaryTextColor;

  @include media(768px) {
    margin-top: 14px;
    font-size: 14px;
  }

  @include media(1280px) {
    margin-top: 10px;
  }
}

.contacts-wrap {
  position: relative;
  margin-bottom: 50px;

  @include media(768px) {
    margin-bottom: 60px;
  }
  @include media(1280px) {
    margin-bottom: 64px;
  }
}

.contacts-wrap span {
  position: absolute;
  bottom: -25px;
  width: 100%;

  border-bottom: 1px solid $customersLinesColor;

  @include media(768px) {
    bottom: -30px;
  }
  @include media(1280px) {
    bottom: -32px;
  }
}

.contacts__item-day {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.83;
  color: $primaryTextColor;

  @include media(768px) {
    font-size: 14px;
  }
}

.contacts__item-time {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.83;
  color: $preTitleTextColor;

  @include media(768px) {
    font-size: 14px;
  }
}

.contacts-wrap__time.upper {
  margin-bottom: 10px;

  @include media(768px) {
    margin-bottom: 16px;
  }
  @include media(1280px) {
    margin-bottom: 15px;
  }
}

.contacts__item-link {
  display: block;

  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: $secondaryTextColor;

  @include media(768px) {
    font-size: 14px;
    line-height: 1.85;
  }
}

.contacts-btn-wrap {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 161px;

  @include media(768px) {
    flex-direction: row;
    width: 100%;
  }
}

.contacts-btn__location {
  margin-bottom: 10px;

  @include media(768px) {
    margin-right: 15px;
    margin-bottom: 0;
    width: 198px;
  }
}

.contacts-btn__franchise {
  @include media(768px) {
    width: 180px;
  }
}

.contacts-btn__location-icon {
  margin-left: 8px;

  stroke: $lightTextColor;
  @include media(768px) {
    margin-left: 13px;
  }
}

.contacts-btn__franchise-icon {
  margin-left: 13px;

  stroke: $accentColor;
}

// -------modal
// backdrop
.contacts__map {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  background-color: rgba(48, 48, 48, 0.376);
  opacity: 1;
  visibility: visible;

  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    visibility 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

// modal window
.contacts__map-wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  min-height: 500px;

  background-color: $primaryBgColor;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  @include media(480px) {
    width: 440px;
  }
  @include media(768px) {
    width: 700px;
  }
}

.map {
  padding: 35px;
}

.map__btn {
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 5px;
  @include centeredFlex;

  background-color: $heroBgColor;
  border: none;
  border-radius: 50%;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    background-color: $accentColor;
  }
}

.iframe {
  width: 100%;
  height: 450px;
}

// -------modal-franchise
.franchise {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;

  background-color: rgba(48, 48, 48, 0.376);
  opacity: 1;
  visibility: visible;

  transition: opacity 250ms cubic-bezier(0.4, 0, 0.2, 1),
    visibility 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  }
}

.modal-franchise {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 320px;
  min-height: 420px;
  padding: 20px;

  background-color: $primaryBgColor;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  @include media(768px) {
    max-width: 400px;
  }

  &__btn {
    display: block;
    margin-left: auto;
    padding: 5px;
    margin-bottom: 25px;

    background-color: $heroBgColor;
    border-radius: 50%;

    transition: background-color 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);

    &:hover,
    &:focus {
      background-color: $accentColor;
    }
  }

  &__btn-icon {
    display: block;
  }

  &__tittle {
    margin-bottom: 50px;

    font-family: 'Titan One';
    @include font(null, 18px, 1.13, 0.06em, uppercase);
    text-align: center;
    color: $accentColor;
  }

  &__wrap {
    position: relative;
    margin-bottom: 30px;
  }

  &__input {
    width: 100%;
    height: 34px;
    padding-left: 16px;

    @include font(500, 14px, 1.33, 0.04em, null);
    font-family: 'DM Sans';

    border-radius: 10px;
    border: 1px solid $heroBgColor;
    outline: $accentColor;

    transition: border-color 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);

    &:hover,
    &:focus {
      border-color: $accentColor;
    }
  }

  &__label {
    position: absolute;
    left: 15px;
    top: -10px;
    transform: translateY(-50%);

    @include font(500, 12px, 1.33, 0.04em, null);
    color: $heroBgColor;

    transition: transform 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);
  }
  &__label-com {
    position: absolute;
    left: 15px;
    top: 10px;
    transform: translateY(-30px);

    @include font(500, 12px, 1.33, 0.04em, null);
    color: $heroBgColor;
  }

  &__comment {
    padding: 12px 16px;
    height: 120px;
    width: 100%;
    @include font(500, 14px, 1.33, 0.04em, null);
    font-family: 'DM Sans';
    resize: none;
    border-radius: 15px;
    border: 1px solid $heroBgColor;
    outline: $accentColor;

    transition: border-color 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);

    &:hover,
    &:focus {
      border-color: $accentColor;
    }
  }

  &__btn-go {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 220px;

    @include font(700, 16px, 1.31, 0.04em, null);

    &:hover,
    &:focus {
      background-color: $heroBgColor;
    }
  }
}

$accentColor: #d41443;

$primaryTextColor: #000000;
$secondaryTextColor: #907e82;
$lightTextColor: #ffffff;
$preTitleTextColor: #ffa5ba;

$primaryBgColor: #ffffff;
$secondaryBgColor: #fff5f6;
$heroBgColor: #ffb8ca;
$heroCircleColor: #ffa5ba;
$menuBgColor: #e17992;
$btnAccentHoverColor: #c7133e;
$btnPrimaryHoverColor: #ecebeb;

$iceBgColorSecondary: #f88ca5;
$coffeeBgColor: #f0d1a5;
$coffeeBgColorSecond: #e8c086;
$milkshakeBgColor: #c2e297;
$milkshakeBgColorSecond: #acd17b;

$socialIconsBg: #fea5bb;
$menuLinesColor: #e18298;
$customersLinesColor: #e1e1e1;
$sliderOptionsColor: #d9d9d9;

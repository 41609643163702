.about {
  background-color: $secondaryBgColor;
  background-repeat: no-repeat;
  background-position: 0 766px;
  background-size: contain;

  @include media(767px, max-width) {
    padding-top: 125px;
    background-image: url(..//..//src/images/section.milk.mob1x.png);

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
      background-image: url(..//..//src/images/section.milk.mob2x.png);
    }
    @media (min-device-pixel-ratio: 3), (min-resolution: 288dpi), (min-resolution: 3dppx) {
      background-image: url(..//..//src/images/section.milk.mob3x.png);
    }
  }

  @include media(768px) {
    padding-top: 130px;
  }

  @include media(1280px) {
    background-image: url(..//..//src/images/section.milk1x.png);
    background-position: bottom;

    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi), (min-resolution: 2dppx) {
      background-image: url(..//..//src/images/section.milk2x.png);
    }
    @media (min-device-pixel-ratio: 3), (min-resolution: 288dpi), (min-resolution: 3dppx) {
      background-image: url(..//..//src/images/section.milk3x.png);
    }
  }
}

.about__pre-title {
  margin-bottom: 15px;
  text-align: center;
}

.about__title {
  margin-bottom: 26px;
  text-align: center;

  @include media(768px) {
    margin-bottom: 50px;
  }
  @include media(1280px) {
    margin-bottom: 55px;
  }
}

.about__box {
  @include media(768px) {
    display: flex;
  }
}

.about__box-wrap {
  width: 100%;
  @include media(767px, max-width) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include media(768px) {
    width: 340px;
    margin-left: 17px;
    padding-top: 50px;
  }
  @include media(1280px) {
    width: 460px;
    margin-left: 74px;
  }
}

.about__img {
  width: 100%;
  height: auto;
  margin-bottom: 46px;

  @include media(768px) {
    width: 340px;
    height: auto;
    margin-bottom: 0px;
  }
  @include media(1280px) {
    width: 516px;
    height: auto;
  }
}

.about__subtitle {
  margin-bottom: 35px;

  font-weight: 500;
  font-size: 12px;
  line-height: 1.62;
  color: $primaryTextColor;

  @include media(768px) {
    font-size: 14px;
    line-height: 1.76;
  }
  @include media(1280px) {
    font-size: 16px;
  }
}

.about__svg {
  width: 5px;
  height: 8px;
  margin-left: 14px;

  stroke: currentColor;
}

.about__subdescription {
  margin-bottom: 10px;

  font-weight: 500;
  font-size: 12px;
  line-height: 1.62;
  color: $secondaryTextColor;

  @include media(768px) {
    font-size: 14px;
    line-height: 1.76;
    letter-spacing: 0.04em;
  }

  @include media(1280px) {
    margin-bottom: 27px;

    line-height: 1.94;
  }
}

.about__subdescription-text {
  margin-bottom: 17px;

  font-weight: 500;
  font-size: 12px;
  line-height: 1.62;
  color: $secondaryTextColor;

  @include media(768px) {
    margin-bottom: 62px;

    font-size: 14px;
    line-height: 1.76;
    letter-spacing: 0.04em;
  }
  @include media(1280px) {
    margin-bottom: 45px;

    line-height: 1.94;
  }
}

.about__button {
  width: 176px;

  font-size: 14px;
  line-height: 1.29;
}

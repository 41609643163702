.pre-title {
  font-family: 'Titan One';
  @include font(400, 18px, 1.17, normal, uppercase);
  color: $preTitleTextColor;

  @include media(768px) {
    font-size: 26px;
    line-height: 1.15;
  }
  @include media(1280px) {
    font-size: 30px;
    line-height: 1.13;
    letter-spacing: 0.04em;
  }
}

.title {
  font-family: 'Titan One';
  @include font(400, 34px, 1.15, 0.04em, uppercase);
  color: $accentColor;

  @include media(768px) {
    font-size: 48px;
  }
  @include media(1280px) {
    font-size: 58px;
    line-height: 1.13;
    letter-spacing: 0.06em;
  }
}

.button-accent {
  height: 44px;
  @include centeredFlex;
  align-items: baseline;
  padding-top: 12px;
  padding-bottom: 12px;

  @include font(700, 16px, 1.3, null, null);
  color: $lightTextColor;
  background-color: $accentColor;
  border-radius: 22px;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    background-color: $btnAccentHoverColor;
  }
}

.button-primary {
  height: 44px;
  @include centeredFlex;
  align-items: baseline;
  padding-top: 12px;
  padding-bottom: 12px;

  @include font(700, 16px, 1.3, null, null);
  color: $accentColor;
  background-color: $primaryBgColor;
  border-radius: 22px;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    background-color: $btnPrimaryHoverColor;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.btn-primary-flicker {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -180px;
    width: 70px;
    height: 100%;
    background-color: $heroCircleColor;
    filter: blur(20px);
    transform: skewX(-30deg);
  }

  &:hover::before,
  :focus::before {
    left: calc(100% + 150px);
    transition: 1s;
  }

  &:hover::after,
  :focus::after {
    left: calc(100% + 150px);
    transition: 1s 0.5s;
  }
}

.btn-accent-flicker {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -180px;
    width: 70px;
    height: 100%;
    background-color: #fff;
    filter: blur(20px);
    transform: skewX(-30deg);
  }

  &:hover::before,
  :focus::before {
    left: calc(100% + 150px);
    transition: 1s;
  }

  &:hover::after,
  :focus::after {
    left: calc(100% + 150px);
    transition: 1s 0.5s;
  }
}

.backdrop__about {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  background-color: rgba(144, 126, 130, 0.6);
  opacity: 1;
  overflow-y: scroll;
  z-index: 5;

  transition: opacity 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.backdrop__modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 248px;
  width: 90%;
  height: 90%;
  padding: 60px 24px;

  overflow: scroll;
  background-color: $primaryBgColor;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
    0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  @include media(768px) {
    width: 700px;
    height: 780px;
    max-height: 90%;
    padding: 85px 65px;
  }
  @include media(1280px) {
    width: 1048px;
    height: 619px;
    max-height: 90%;
  }
}

.about__btn {
  position: absolute;
  right: 28px;
  top: 22px;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: $heroBgColor;
  border-radius: 50%;
  transition: background-color 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);

  &:hover,
  &:focus {
    background-color: $accentColor;
  }

  &-icon {
    display: block;
  }
}

.backdrop__article {
  margin-bottom: 16px;

  @include font(500, 16px, 1.76, 0.04em, none);
  color: $primaryTextColor;
}

.backdrop__text {
  @include font(500, 14px, 1.94, 0.04em, none);
  color: $secondaryTextColor;
}

.backdrop__margin {
  padding: 35px 0 35px 0;
}

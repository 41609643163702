.page-header .container {
  position: relative;
}

.page-header__wrap {
  position: absolute;
  width: calc(100% - (20px * 2));
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
  padding-top: 6px;

  @include media(768px) {
    padding-top: 37px;
  }
}

.logo {
  display: flex;
  align-items: center;

  font-family: 'Titan One';
  @include font(null, 26px, 1.15, 0.03em, uppercase);
  color: $accentColor;

  &__item {
    margin-right: 15px;
  }
  @include media(1280px) {
    position: relative;
    left: -50px;
  }
}

.site-nav {
  @include media(1279px, max-width) {
    display: none;
  }

  &__item:not(:last-child) {
    margin-right: 15px;
  }

  &__list {
    display: flex;
  }

  &__wrap {
    display: flex;
  }

  &__link {
    display: block;
    padding: 10px;

    @include font(700, 16px, 1.31, null, null);
    color: $lightTextColor;
    transition: color 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);

    &:hover,
    &:focus {
      color: $accentColor;
    }
  }
}

.mob-menu-open {
  padding: 8px;

  @include media(1280px) {
    display: none;
  }

  &__icon {
    display: block;
  }
}

.order-btn {
  min-width: 160px;

  &__icon {
    margin-left: 12px;
    stroke: currentColor;
  }

  @include media(767px, max-width) {
    display: none;
  }

  @include media(1279px, max-width) {
    margin-left: 15px;
  }
}

.footer {
  background-color: $secondaryBgColor;
}

.footer__wrap {
  margin-bottom: 43px;

  @include media(768px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 91px;
  }
  @include media(1280px) {
    margin-bottom: 97px;
  }
}

.footer__titles-box {
  width: 100%;
  min-width: 234px;
  max-width: 70%;
  margin-bottom: 43px;

  @include media(768px) {
    width: 340px;
    max-width: 340px;
    padding-top: 7px;
    margin-bottom: 0;
  }
  @include media(1280px) {
    width: max-content;
    max-width: max-content;
    padding-top: 0;
  }
}

.footer__duplicate-pre-title {
  margin-bottom: 17px;

  @include media(768px) {
    margin-bottom: 16px;

    letter-spacing: 0.04em;
  }
  @include media(1280px) {
    margin-bottom: 15px;
  }
}

.footer__duplicate-title {
  @include media(768px) {
    letter-spacing: 0.06em;
  }
}

.footer__contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

// socials list
.socials {
  display: flex;
  margin-bottom: 46px;

  @include media(1280px) {
    margin-bottom: 50px;
  }
}

.socials__item {
  width: 32px;
  height: 32px;

  &:not(:last-child) {
    margin-right: 28px;
  }
  @include media(768px) {
    width: 34px;
    height: 34px;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  @include media(1280px) {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.socials__link {
  display: block;
  width: 100%;
  height: 100%;

  background-color: $socialIconsBg;
  border-radius: 50%;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1);

  &:hover,
  &:focus {
    background-color: $accentColor;
  }
}

.socials__icon {
  width: 100%;
  height: 100%;

  fill: $lightTextColor;
}

// telephone
.footer__tel {
  @include font(700, 12px, 1.86, 0.02em, null);
  color: $primaryTextColor;

  @include media(768px) {
    font-size: 14px;
  }
  @include media(1280px) {
    font-size: 16px;
  }
}

.footer__tel-link {
  color: $accentColor;
}

// copyright block
.copyright-wrap {
  width: 100%;
  padding-top: 22px;
  padding-bottom: 25px;

  border-top: 1px solid $customersLinesColor;

  @include media(768px) {
    padding-top: 29px;
    padding-bottom: 18px;
  }
  @include media(1280px) {
    padding-top: 9px;
    padding-bottom: 10px;
  }
}

.copyright-desc {
  @include media(479px, max-width) {
    width: 150px;
    margin: 0 auto;
  }
  @include media(480px) {
    width: 100%;
    margin: 0 auto;
  }

  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: 0.02em;
  color: $secondaryTextColor;
}

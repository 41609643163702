p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  font-style: normal;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  padding: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  @include media(479px, max-width) {
    width: 100%;
  }
  @include media(480px) {
    width: 480px;
  }
  @include media(768px) {
    width: 740px;
  }
  @include media(1280px) {
    width: 1090px;
  }
}

.button-up {
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 5;
  width: 64px;
  height: 64px;
  @include centeredFlex;

  stroke: $accentColor;
  background-color: $heroBgColor;
  border-radius: 50%;

  &:hover,
  &:focus {
    background-color: $btnPrimaryHoverColor;
  }

  &__icon {
    transform: rotate(-90deg);
  }
}

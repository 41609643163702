.products {
  padding: 121px 0 98px 0;

  text-align: center;

  @include media(767px, max-width) {
    padding: 121px 0 98px 0;
  }

  @include media(768px) {
    padding: 122px 0 112px 0;
  }

  @include media(1280px) {
    padding: 127px 0 119px 0;
  }
}

.products__pre-title {
  margin-bottom: 15px;
}

.products__title {
  @include media(767px, max-width) {
    margin-bottom: 144px;
  }
  @include media(768px) {
    margin-bottom: 150px;
  }
  @include media(1280px) {
    margin-bottom: 259px;
  }
}

.products__list {
  display: flex;

  @include media(767px, max-width) {
    flex-wrap: wrap;
    justify-content: center;
  }
  @include media(768px) {
    justify-content: space-between;
  }
  @include media(1280px) {
    justify-content: space-between;
  }
}

.products__item {
  position: relative;
  height: 485px;

  background-color: $coffeeBgColor;
  border-radius: 24px;

  &:first-child {
    background-color: $heroBgColor;
  }
  &:last-child {
    background-color: $milkshakeBgColor;
  }

  transition: box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1),
    transform 250ms cubic-bezier(0.4, 0, 0.2, 1);

  @include media(767px, max-width) {
    &:not(:last-child) {
      margin-bottom: 130px;
    }
  }
  @include media(767px, max-width) {
    min-width: 100%;
  }
  @include media(768px) {
    width: 220px;
  }
  @include media(1280px) {
    width: 335px;
    height: 525px;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.2);
  }
}

.products__img {
  position: absolute;
  top: -113px;
  width: 100%;
  z-index: 1;

  background-position: 50% 0;
  background-repeat: no-repeat;

  @include media(767px, max-width) {
    height: 252px;
    background-size: 249px 100%;
  }
  @include media(768px) {
    top: -102px;
    height: 224px;
    background-size: 220px 100%;
  }
  @include media(1280px) {
    top: -210px;
    height: 335px;
    background-size: 335px 100%;
  }
}

.img-1 {
  background-image: url(..//..//src/images/product-ice1.png);
}

@media (min-device-pixel-ratio: 2),
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi),
  (min-resolution: 2dppx) {
  .img-1 {
    background-image: url(..//..//src/images/product-ice1@2x.png);
  }
}

@media (min-device-pixel-ratio: 3),
  (-webkit-min-device-pixel-ratio: 3),
  (min-resolution: 288dpi),
  (min-resolution: 3dppx) {
  .img-1 {
    background-image: url(..//..//src/images/product-ice1@3x.png);
  }
}

.img-2 {
  background-image: url(..//..//src/images/product-ice2.png);
}
@media (min-device-pixel-ratio: 2),
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi),
  (min-resolution: 2dppx) {
  .img-2 {
    background-image: url(..//..//src/images/product-ice2@2x.png);
  }
}

@media (min-device-pixel-ratio: 3),
  (-webkit-min-device-pixel-ratio: 3),
  (min-resolution: 288dpi),
  (min-resolution: 3dppx) {
  .img-2 {
    background-image: url(..//..//src/images/product-ice2@3x.png);
  }
}

.img-3 {
  background-image: url(..//..//src/images/product-ice3.png);
}

@media (min-device-pixel-ratio: 2),
  (-webkit-min-device-pixel-ratio: 2),
  (min-resolution: 192dpi),
  (min-resolution: 2dppx) {
  .img-3 {
    background-image: url(..//..//src/images/product-ice3@2x.png);
  }
}

@media (min-device-pixel-ratio: 3),
  (-webkit-min-device-pixel-ratio: 3),
  (min-resolution: 288dpi),
  (min-resolution: 3dppx) {
  .img-3 {
    background-image: url(..//..//src/images/product-ice3@3x.png);
  }
}

.products__subtitle {
  position: relative;
  margin-bottom: 30px;
  margin-top: 161px;
  z-index: 1;

  font-family: 'Titan One';
  @include font(400, 20px, 1.15, 0.04em, uppercase);
  color: $lightTextColor;

  @include media(768px) {
    margin-top: 152px;
  }
  @include media(1280px) {
    margin-top: 163px;
    margin-bottom: 32px;
    @include font(400, 30px, 1.13, 0.04em, uppercase);
  }
}

.products__points {
  margin-bottom: 30px;

  @include media(1280px) {
    margin-bottom: 32px;
  }
}

.products__subdescription {
  color: $lightTextColor;

  @include media(767px, max-width) {
    padding: 0px 40px;
    margin-bottom: 30px;
    @include font(700, 14px, 1.52, 0.04em, none);
  }
  @include media(768px) {
    padding: 0px 11px;
    margin-bottom: 40px;
    @include font(700, 14px, 1.76, 0.04em, none);
  }
  @include media(1280px) {
    padding: 0px 44px;
    margin-bottom: 62px;
    @include font(700, 16px, 1.76, 0.04em, none);
  }
}

.products__button {
  position: relative;

  width: 40px;
  height: 40px;
  margin: 0;
  z-index: 1;

  text-align: center;

  border-radius: 50%;
  background-color: $primaryBgColor;

  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), transform 250ms;

  &:hover,
  &:focus {
    background-color: $btnPrimaryHoverColor;
  }

  .products__hiden-description.is-open1 ~ &,
  .products__hiden-description.is-open2 ~ &,
  .products__hiden-description.is-open3 ~ & {
    transform: rotate(-180deg);
  }
}

.overflow {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.products__hiden-description {
  position: absolute;
  top: 0;
  transform: translatex(-100%);
  height: 100%;
  padding: 196px 10px 0 10px;

  text-align: center;

  border-radius: 24px;
  color: $lightTextColor;

  @include font(700, 16px, 1.52, 0.04em, none);
  @include media(768px) {
    @include font(700, 14px, 1.76, 0.04em, none);
  }
  @include media(1280px) {
    padding-top: 210px;
    @include font(700, 16px, 1.76, 0.04em, none);
  }

  transition: transform 250ms ease;

  &.is-open1,
  &.is-open2,
  &.is-open3 {
    transform: translatex(0);
  }

  &.second {
    background-color: $coffeeBgColorSecond;
  }
  &.first {
    background-color: $iceBgColorSecondary;
  }

  &.third {
    background-color: $milkshakeBgColorSecond;
  }
}

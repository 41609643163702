.basket::before,
.basket::after {
  content: '';
  position: absolute;
  z-index: -1;

  border-radius: 4px;

  background: linear-gradient(
    45deg,
    $accentColor,
    rgb(70, 50, 187),
    $secondaryTextColor,
    $milkshakeBgColor,
    $menuLinesColor,
    rgb(79, 128, 219),
    rgb(214, 162, 162),
    $accentColor
  );
  background-size: 400%;
  animation: gradient 20s linear infinite;
}

.basket::after {
  top: -8px;
  left: -8px;
  width: calc(100% + 16px);
  height: calc(100% + 16px);
  filter: blur(30px);
}

.basket::before {
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
}

@keyframes gradient {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0 0;
  }
}

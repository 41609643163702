.backdrop-buynow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include centeredFlex;
  z-index: 10;

  background-color: rgba(144, 126, 130, 0.6);
  overflow-y: scroll;

  transition: opacity 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);

  &.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.btn-price {
  margin-left: 10px;
  margin-right: 5px;
}

.basket {
  position: relative;
  width: 98%;

  max-width: 320px;
  min-height: 600px;
  padding: 25px;

  background-color: $primaryBgColor;

  border-radius: 4px;

  @include media(768px) {
    max-width: 702px;
    min-height: 815px;
  }

  &__btn {
    display: block;
    margin-left: auto;
    padding: 5px;
    margin-bottom: 25px;

    background-color: $heroBgColor;
    border-radius: 50%;

    transition: background-color 250ms cubic-bezier(0.07, 0.96, 0.54, 0.88);

    &:hover,
    &:focus {
      background-color: $accentColor;
    }
  }

  &__btn-icon {
    display: block;
  }

  &__tittle {
    margin-bottom: 25px;

    font-family: 'Titan One';
    @include font(null, 20px, 1.13, 0.06em, uppercase);
    text-align: center;
    color: $accentColor;

    @include media(768px) {
      margin-bottom: 40px;
      font-size: 24px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 38px;
    }
  }

  &__wrap {
    margin: 0 auto;
    max-width: max-content;
    @include media(768px) {
      display: flex;
      align-items: center;
    }
  }

  &__image {
    flex-grow: 1;
  }

  &__text {
    @include font(700, 16px, 1.31, null, null);
    color: $accentColor;

    @include media(767px, max-width) {
      margin-bottom: 10px;
    }

    @include media(768px) {
      margin-right: 72px;
      font-size: 24px;
    }
  }

  &__btn-wrap {
    display: flex;
    align-items: center;

    @include media(767px, max-width) {
      margin-bottom: 10px;
      justify-content: space-between;
    }

    @include media(768px) {
      margin-right: 45px;
    }
  }

  &__btn-minus,
  &__btn-plus {
    position: relative;
    @include centeredFlex;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background-color: $secondaryBgColor;

    &:hover,
    &:focus {
      background-color: $btnPrimaryHoverColor;
    }

    &::before {
      content: '';
      position: absolute;
      top: calc(50% -1px);
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 16px;
      height: 1px;
      background-color: $accentColor;
    }
  }

  &__btn-plus::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 16px;
    height: 1px;
    background-color: $accentColor;
    transform: rotate(90deg);
  }

  &__quantity {
    @include font(700, 16px, 1.31, null, null);
    color: $accentColor;

    @include media(768px) {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 18px;
    }
  }

  &__price {
    display: block;
    text-align: center;
    @include font(700, 16px, 1.31, null, null);
    color: $accentColor;

    @include media(768px) {
      font-size: 18px;
    }
  }

  &__btn-go {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 220px;
    margin-top: 40px;

    @include font(700, 16px, 1.31, 0.04em, null);

    &:hover,
    &:focus {
      background-color: $heroBgColor;
    }
  }

  // &__input:focus + &__label,
  // &__input:not(:placeholder-shown) + &__label {
  //   transform: translateY(-37px);
  // }
}

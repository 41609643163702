.page {
  font-family: 'DM Sans', sans-serif;
  color: $primaryTextColor;
  font-size: 16px;
  line-height: 1.76;
}

.page.modal-open,
.page.menu-open,
.page.map-open {
  overflow: hidden;
}
